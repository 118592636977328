import HomeImageCover from '../components/home/image-cover';
import Education from '../components/home/education';
import styled from "styled-components";
import WrapperPage from '../components/wrapper-page';
import PreinscripcionsPromo from '../components/home/preinscripcions-promo';
import Books from '../components/home/books';
import StartSchool from '../components/home/start-school';
import PromoAlertInfo from '../components/home/promo-alert-info';
import { Helmet } from "react-helmet";

export const HomeContent = styled.div`
    padding: 24px 0;
`;

function Home() {
    return (
        <HomeContent>
            <Helmet>
                <title>Institut Escola Municipal de Treball de Granollers</title>
                <meta name="description" content="Institut Escola Municipal de Treball de Granollers" />
            </Helmet>
            <WrapperPage>
                <PromoAlertInfo />
                <HomeImageCover />
                <StartSchool />
                <PreinscripcionsPromo />
                <Books />
                <Education />
            </WrapperPage>
        </HomeContent>
    );
}

export default Home;