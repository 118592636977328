
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { HiDownload } from "react-icons/hi";
import { FaLink } from "react-icons/fa";
import { ROUTES } from "../../constants/routes";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Text = styled.div`
    padding: 24px 0;

    @media (max-width: 900px){
        width: 100%;
    }
`;

const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const Info = styled.div`
    padding-bottom: 24px;

    @media (max-width: 600px){
        padding-bottom: 0;
        font-size: 14px;
    }
`;

const SubTitulo = styled.div`
    font-weight: bold;
    text-align: center;
    padding: 12px 0;
    background-color: #c82d2d;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-size: 21px;

    @media(max-width: 900px){
        font-size: 18px;
    }
`;

const Sections = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width: 1200px){
        flex-direction: column;
    }

    @media (max-width: 600px){
        gap: 16px;
    }
`;

const Section = styled.div`
    width: 33.333%;
    @media (max-width: 1200px){
        width: 100%;
    }
`;

const Subsection = styled.div`
    padding: 24px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    min-height: 500px;
    /*border-top: 6px solid #c82d2d;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;*/

    @media(max-width: 1200px){
        min-height: initial;
    }

    @media(max-width: 600px){
        font-size: 14px;
        padding: 16px;

    }
`;

const List = styled.ul<{ hideBullet?: boolean }>`
    list-style-type: ${props => props.hideBullet ? 'none' : 'inherit'};
`;

const ListItem = styled.li`
    cursor: pointer;
`;

const ListTitle = styled.div`
    font-weight: bold;
`;



function Tramits() {

    const openUrl = (url: string) => {
        window.open(url, "_blank", 'noopener,noreferrer');
    }

    return (
        <Content >
            <Helmet>
                <title>Tràmits</title>
                <meta name="description" content="Tràmits" />
            </Helmet>
            <Wrapper>
                <Titulo>Tràmits</Titulo>
                <Info>
                    A continuació podeu trobar els diferents tràmits que es poden fer a secretaria. Per a la seva realització, heu d’omplir el document que trobareu a cada tràmit i presentar-lo, si cal amb tota la documentació necessària, a les oficines de secretaria dins l’horari d’atenció al públic.
                    El centre disposa d’un servei de copisteria a la planta inferior de l’edifici per si necessiteu imprimir algun document o fer fotocòpies (la copisteria accepta pagament en efectiu i en targeta).
                </Info>
                <Text>
                    <Sections>
                        <Section>
                            <SubTitulo>Actuals alumnes</SubTitulo>
                            <Subsection>
                                <ListTitle>Documents generals</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1Ip_rnVC3Hr2_CUSEVACEMOKrzZsGRvDn/view?usp=sharing')}>Instància genèrica <HiDownload color='#343a40' size="1em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1qigvaZRyELfAAM0ROWfcdpreDOP4LOy5/view?usp=sharing')}>Baixa escolar <HiDownload color='#343a40' size="1em" /></ListItem>
                                </List>
                                <ListTitle>Tràmits per alumnat ESO</ListTitle>
                                <List>
                                    <ListItem onClick={() => window.open(ROUTES.TramitsConvalidacioEso, "_self")}>Convalidació Música <FaLink color='#343a40' size="0.8em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1Ip_rnVC3Hr2_CUSEVACEMOKrzZsGRvDn/view?usp=drive_link')}>Petició canvi d’optativa <HiDownload color='#343a40' size="1em" /></ListItem>
                                </List>
                                <ListTitle>Tràmits per alumnat Batxillerat</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl(ROUTES.TramitsConvalidacioBatxillerat)}>Convalidació Música <FaLink color='#343a40' size="0.8em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1mUkp3uPUfe4sJp7Hdfit7wEIOwq6kwtY/view?usp=sharing')}>Petició canvi de matèria o modalitat <HiDownload color='#343a40' size="1em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1pP88Sd8Vslm6c1zrOABZHuivoamBNtTe/view?usp=sharing')}>Acord de partició del curs <HiDownload color='#343a40' size="1em" /></ListItem>
                                </List>
                                <ListTitle>Tràmits Cicles Formatius</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl(ROUTES.TramitsConvalidacioFp)}>Convalidacions cicles formatius <FaLink color='#343a40' size="0.8em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1I84QXpq3l1gdUQdHuxFUsm5j_WwYv0dy/view?usp=sharing')}>Exempció a la Formació en Centres de Treball (FCT) <HiDownload color='#343a40' size="1em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1k2NCgjQsslz5FE7Upc-DTmGX8Jvy2o-X/view?usp=sharing')}>Anul·lació d’unitats formatives <HiDownload color='#343a40' size="1em" /></ListItem>
                                </List>
                            </Subsection>
                        </Section>
                        <Section>
                            <SubTitulo>Certificats</SubTitulo>
                            <Subsection>
                                <ListTitle>Sol·licitud</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1FUpuNOFSXffOug1-qZz2v2dq07C2zg7H/view?usp=sharing')}>Sol·licitud de certificats acadèmics <HiDownload color='#343a40' size="1em" /></ListItem>
                                    <ListItem onClick={() => window.open(ROUTES.TramitsNivellCatala, "_self")}>Certificat nivell de català <FaLink color='#343a40' size="0.8em" /></ListItem>
                                </List>
                                <ListTitle>Si no pots asistir-hi...</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl('https://drive.google.com/file/d/1e0GqHgJ4HGMcUmfkEtyK29x3UVBLu8JO/view?usp=sharing')}>Autorització recollida de títols <HiDownload color='#343a40' size="1em" /></ListItem>

                                </List>
                            </Subsection>
                        </Section>
                        <Section>
                            <SubTitulo>Altres tràmits</SubTitulo>
                            <Subsection>
                                <ListTitle>Aquests tràmits no es realitzen a la secretaria del centre, però podeu consultar la informació necessària a continuació.</ListTitle>
                                <List>
                                    <ListItem onClick={() => openUrl('https://educacio.gencat.cat/ca/tramits/tramits-temes/20264_homologacio_titols')}>Homologacions de títols estrangers no universitaris <FaLink color='#343a40' size="0.8em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://educacio.gencat.cat/ca/serveis-tramits/beques/beques-postobligatoris/index.html')}>Beques per a ensenyaments postobligatoris no universitaris <FaLink color='#343a40' size="0.8em" /></ListItem>
                                    <ListItem onClick={() => openUrl('https://educacio.gencat.cat/ca/tramits/tramits-temes/ajuts-nee')}> Ajuts per a alumnes amb necessitat específica de suport educatiu <FaLink color='#343a40' size="0.8em" /></ListItem>
                                </List>
                            </Subsection>
                        </Section>
                    </Sections>
                </Text >
            </Wrapper >
        </Content >
    );
}

export default Tramits;