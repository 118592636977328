
import styled from "styled-components";
import Wrapper from "../../../components/wrapper";
import { HiDownload } from "react-icons/hi";
import Box from "../../../components/box";
import { Alert } from "react-bootstrap";
import theme from "../../../constants/theme";
import { Helmet } from 'react-helmet';


const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Info = styled.div`
    padding: 24px 0;
    @media(max-width: 600px){
        font-size: 14px;
        padding: 0 0 24px;
    }
`;


const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 24px;
    @media(max-width: 600px){
        font-size: 18px;
        padding-bottom: 12px;
        line-height: 21px;
    }
`;

const TriTitle = styled.div`
    font-weight: bold;
    padding-bottom: 12px;
`;

const Description = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
        padding-bottom: 12px;
    }
`;

const Section = styled.div`
    padding-bottom: 48px;
`;

const DescriptionNoPadding = styled.div`
    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const List = styled.ul`
        @media(max-width: 600px){
        padding-left: 12px;
    }
`;

const Item = styled.li`
    cursor: pointer;
    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const ItemWithLink = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
    @media(max-width: 600px){
        font-size: 14px;
    }
`;

function TramitsConvalidacionsFp() {

    const openUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <Content >
            <Helmet>
                <title>Convalidacions Cicles Formatius</title>
                <meta name="description" content="Convalidacions Cicles Formatius" />
            </Helmet>
            <Wrapper>
                <Titulo>Convalidacions Cicles Formatius</Titulo>
                <Info>
                    Per sol·licitar les convalidacions de cicles formatius cal estar matriculat al centre i el termini per sol·licitar-les sol finalitzar a les dues setmanes d’haver començat el curs acadèmic, és a dir, a finals de setembre. Quan el tutor us entregui el calendari acadèmic, allà trobareu la data exacte de finalització del termini.
                </Info>
                <Section>
                    <Subtitle>Per fer efectiva la petició cal:</Subtitle>
                    <Box>
                        <List>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1yWec2NsiXrtYJMk3DYpUHiX8VeA2eOW4/view?usp=sharing')}>
                                <b>Emplenar la sol·licitud de convalidació</b><HiDownload color='#343a40' size="1em" />
                            </Item>
                            <Item><b>Adjuntar:</b>
                                <List>
                                    <Item>
                                        Si tens un altre cicle formatiu cal presentar el certificat de qualificacions, signat pel/per la Secretari/a i Director/a del centre de procedència, on consti els ensenyaments cursats amb la totalitat de les unitats formatives / crèdits, durada i qualificació obtinguda.
                                    </Item>
                                    <Item>
                                        Si tens un Certificat de professionalitat, amb indicació explícita de les unitats de competència acreditades, cal presentar aquest certificat o la resolució del SOC. No serà vàlid el certificat d’aprofitament o la sol·licitud de petició.
                                    </Item>
                                    <Item>
                                        Si tens estudis oficials d’idiomes, cal presentar el títol corresponent.
                                    </Item>
                                    <Item>
                                        Si tens estudis universitaris i només demanes crèdits o unitats formatives transversals (IPO I, IPO II, FOL, RET, RAT, AGCE, EIE) o crèdits o unitats formatives de lliure disposició, cal presentar el títol o resguard (o certificat acadèmic en cas de no estar acabats).
                                    </Item>
                                    <Item>
                                        Si tens estudis universitaris i demanes altres crèdits o unitats formatives diferents dels anteriors, cal presentar títol o resguard de títol (o certificat acadèmic cas de no estar acabats), pla d’estudis cursat i programes de les matèries cursades a la Universitat que es corresponen amb els crèdits o unitats formatives demanades.
                                    </Item>
                                </List>
                            </Item>
                        </List>
                        <DescriptionNoPadding>
                            <b>No serà vàlid un butlletí de notes</b>. De tots els documents que es presentin necessitarem l’original i una fotocòpia per tal que puguem realitzar la compulsa.
                        </DescriptionNoPadding>
                    </Box>
                </Section>
                <Section>
                    <Subtitle>Taula de convalidacions</Subtitle>
                    <Box>
                        <TriTitle>
                            Matèries transversals:
                        </TriTitle>
                        <List>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1G1Hu_HcApfFEWUph-tAd1DYJA4XKTCpf/view?usp=sharing')}>Mòduls o crèdits de IPO I - IPO II - FOL – EIE – RET<HiDownload color='#343a40' size="1em" /></Item>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1jo3vvc5EWn6PavODak3YFvgKNBZZDjGm/view?usp=sharing')}>Mòdul Anglès<HiDownload color='#343a40' size="1em" /></Item>
                        </List>
                        <TriTitle>
                            Taules de convalidacions Cicles Formatius de Grau Mitjà
                        </TriTitle>
                        <List>
                            <Item>
                                AG10 – CFGM Gestió administrativa
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1XsfTd7P7hRThamZfJ8HrNluzFu-Of5MU/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1s4hR4hrWShwtBOJHKzwFp_EVZeKTIB6s/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                TM10 – CFGM Electromecànica de vehicles automòbils
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1oKK3JJ-vv3oPu1cumKNqlkOk3VJhbH7t/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1JN5xjLVeianknNyoZVU14EL30CAjRBFj/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                EE10 – CFGM Instal·lacions elèctriques i automàtiques
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1klYgRXrScyT6MG53oQFnMGxFKuKqbodx/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1720tPEw922yiP2x4w_nunfDWFpHS3L6j/view?usp=sharing')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                IM10 – CFGM Manteniment electromecànic
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1xCs3VdldtVD3ckavLwKWtgx0Q49A_lGq/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1vKIjPguGZ2d2IMTHWpl-vj_ISv1T18Mu/view?usp=sharing')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                EA20 – CFGM Xarxes i estacions de tractament d’aigües
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1DJC7nRStJT3OyYIlOtk3cwJIoPvlBdoK/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1mTtj6NNcwb969qXztTJX_wZnaF5QyFZj/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                FM20 – CFGM Mecanització
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/12DzizeVoJcwx7-MKz_NzOjfhbXAOXMa7/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1vJsCikhHe5uG3epkGV9unUiUNX4AJtOt/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                QU20 – CFGM Operacions de laboratori
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1EMFH1XZjC8KWOuYZNV6TKxrD2MGscgU8/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1V3EykDjOeq-hli9BDogM162CPurc7ib-/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                SA30 – CFGM Emergències sanitàries
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1RCAnDZhY-HwY_83ZFALbXmIUk59Ultmu/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1Q2Xfbz7WbiEqPjtHlz6sYJTpshG-nm1e/view?usp=drive_link')}> Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                1601 – CFGM Cures auxiliars d’infermeria &darr;
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1ziqql8pKybFPg2KxwKvNf4ojql57_5Q4/view?usp=sharing')}>Pla actual &darr;</Link>
                                </ItemWithLink>
                            </Item>
                        </List>
                        <TriTitle>
                            Taules de convalidacions Cicles Formatius de Grau Superior
                        </TriTitle>
                        <List>
                            <Item>
                                AGB0 – CFGS Administració i finances
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/14hsb5S_VGIqvY8BUlgFRNzTNvaFe70Sb/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1wydrbeh6mk6G1gvTfvvb2Yn90pRAN8BU/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                TMA0 – CFGS Automoció
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1gkfOLbVHZpqbLcI9Bugz9_80S1FH6w9K/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1trw-GM2o94U4NsoGjXilutKXqG1xEjo-/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                EEB0 – CFGS Automatització i robòtica industrial
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/135BujJ-el6Rn2TSWdx3gZ65mODXW1cqx/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1EuL5j6MwJrcoeZT5ArJKmdGLBfZ-dLjh/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                FMB0 – CFGS Programació de la producció en fabricació mecànica
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1Z2tjV4cQKIN49Sw66jAI6v4mf2fb6_NV/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1vWxtoCPWSRVs6_17N6MWmNp0FlyjBrCm/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                FMC0 – CFGS Disseny en fabricació mecànica
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1aBT2cR4rdMaWF-nZFQt9P7H1k6aB1Jxw/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/13pV_-m5dPNNGZAPyN9aqJOmC2NfGQR7g/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                QUD0 – CFGS Laboratori d’anàlisi i control de qualitat
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1v1trPQgRNVHYKs-v1KHuCbV2EKDyaAbt/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/17W0ggwKYevx72dbQbILle5ZJGRvGOqPa/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                            <Item>
                                SAE0 – CFGS Laboratori clínic i biomèdic
                                <ItemWithLink>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/1JKj--arT4bMzXA6GZgW9lgnXaj1mKTpu/view?usp=sharing')}>Pla antic &darr;</Link>
                                    <Link onClick={() => openUrl('https://drive.google.com/file/d/17W0ggwKYevx72dbQbILle5ZJGRvGOqPa/view?usp=drive_link')}>Pla nou &darr;</Link>
                                </ItemWithLink>
                            </Item>
                        </List>
                        <DescriptionNoPadding>
                            Recorda que els mòduls i les unitats formatives sol·licitades a la convalidació són les que tramitarem literalment.
                            Qualsevol errada de codis, matèries i noms de la sol·licitud serà atribuïda a la persona que realitza la petició. Consulteu amb el tutor/tutora el nom oficial de les matèries sol·licitades a convalidar d’acord a com queda reflectit en el pla d’estudis del cicle formatiu. <br /><br />
                            Per tal de facilitar el tràmit al final del document trobareu les taules de convalidacions de cada cicle formatiu.
                        </DescriptionNoPadding>
                    </Box>
                </Section>

                <Section>
                    <Subtitle>
                        Resolució de les convalidacions
                    </Subtitle>
                    <Box>
                        <Description>
                            <b>Molt important! La sol·licitud no implica que els mòduls i/o les unitats formatives estiguin convalidades</b>. Això significa que l’alumne ha de continuar assistint a classe fins que rebi la resolució. A la resolució s’indicarà si les unitats formatives / crèdits estan convalidats o si s’ha denegat la convalidació.
                            Cal tenir present que hi ha convalidacions que les resolen la Direcció General de Formació Professional i altres que es resolen directament des del centre.
                        </Description>
                        <List>
                            <Item>Les convalidacions que es resolen des de la Direcció General de Formació Professional tarden mesos en resoldre’s. Una vegada haguem rebut la resolució  per part de la Direcció General, avisarem a l’alumne per tal que vingui a buscar-les.
                            </Item>
                            <Item>Les convalidacions que es resolen des del centre són més àgils i es solen resoldre durant el mes de novembre </Item>
                        </List>
                        <Description>
                            La convalidació tindrà la qualificació que estableixi la normativa vigent, pel qual s’estableixen convalidacions de mòduls professionals dels títols de Formació Professional del sistema educatiu espanyol i les mesures per a la seva aplicació.
                        </Description>
                    </Box>
                </Section>
            </Wrapper>
        </Content>
    );
}

export default TramitsConvalidacionsFp;